import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { parsePost, parsePostCategory } from '@services/prismic/helpers'
import Box from "@lib/ui/core/Box"
import Container from "@layouts/Container"
import List from './List'

const Data = ({ posts, category }) => {
  return (
    <>
      <Box mb={1}>
        <Container>
          <List posts={posts} category={category} />
        </Container>
      </Box>
    </>
  )
}

export default React.memo(Data)
