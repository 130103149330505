import React, { useState, useEffect, useRef } from 'react'
import Img from 'gatsby-image'
import Box from '@lib/ui/core/Box'
import Carousel from '@lib/ui/core/Carousel'
import Grid from '@lib/ui/core/Grid'
import useImpression from '@hooks/use-impression'
import { addGaEvent } from '@utils/ga-event'


const Slider = ({ url, type, target, image, aspectRatio, key, gaType }) => {
  const eventCategory = gaType === 'creditCard' ? "card_comparison_page_carousel" : "homepage_carousel_image";
  const ga4Event = gaType === 'creditCard' ? "card_comparison_page_carousel_impression" : "homepage_carousel_image_impression";
  const itemType = gaType === 'creditCard' ? 'card' : type
  const eventParams = {
    item_url: url,
    index: key,
    item_type: itemType,
  };
  const gaEvent = {
    eventCategory,
    eventLabel: url,
    ga4Event,
    eventParams,
    once: true,
  };
  const { observe } = useImpression(gaEvent);

  const handleImgClick = () => {
    if (gaType !== 'creditCard') return
    addGaEvent({
      ga4Event: "card_comparison_page_carousel_click",
      eventParams,
    })
  }

  return (
    <a href={url} target={target} data-ele-name="carousel-image" ref={observe}>
      <Box bgcolor="grey.100" onClick={handleImgClick} clone>
        {image && (
          <Img fluid={{ ...image.fluid, aspectRatio }} url={url} alt={image.alt} />
        )}
      </Box>
    </a>
  );
}

const HeroCarousel = ({ items, type, aspectRatio = 2.5 }) => {
  const [state, setState] = useState({ imageSliderNav: null, descriptionSliderNav: null, arrowSliderNav: null });
  const imageSlider = useRef();
  const descriptionSlider = useRef();
  const arrowSlider = useRef();

  useEffect(() => {
    setState({
      imageSliderNav: imageSlider.current,
      descriptionSliderNav: descriptionSlider.current,
      arrowSliderNav: arrowSlider.current,
    });
  }, []);

  const { imageSliderNav } = state;

  return (
    <Box bgcolor={{ xs: "background.default", sm: "grey.100" }} borderRadius={{ xs: 0, sm: ".75rem" }} overflow="hidden">
      <Grid container alignItems="stretch" spacing={0}>
        <Grid item xs={12} sm={12}>
          <Carousel initialSlide={items && items[0] ? Math.floor(Math.random() * items.length) : 0} asNavFor={imageSliderNav} ref={(slider) => { imageSlider.current = slider }}>
            {(items && items[0] ? items : []).map((item, index) => (
              <Slider {...item} gaType={type} aspectRatio={aspectRatio} key={index} />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HeroCarousel
