import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { graphql } from "gatsby"
import { parseOffer, parseSiteConfig } from "@services/prismic/helpers"
import { parsePost, parsePostCategory } from '@services/prismic/helpers'
import Box from "@lib/ui/core/Box"
import Button from "@lib/ui/core/Button"
import Typography from "@lib/ui/core/Typography"
import Link from '@lib/ui/core/Link'
import Hidden from "@lib/ui/core/Hidden"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import SEO from "@components/SEO"
import JsonLd from "@components/JsonLd"
import Top from "@components/Home/Top"
import HomepageCarousel from "@components/Home/HomepageCarousel"
import SearchInput from "@components/Search/SearchInput"
import OfferList from "@components/Offer/List"
import OfferFeatured from "@components/Offer/Featured"
import OfferShowMore from "@components/Offer/ShowMore"
import Divider from "@components/Divider"
import organizationSchema from "@utils/json-ld/organization"
// import { OfferContextStore } from "@context"
// import Subscription from "@components/Subscription"
// import Announcement from "@components/Announcement"
import EmailPopup from "@components/EmailPopup"
import useEmailPopup from "@hooks/use-view-scroll"
import { useAllCreditCard } from "@hooks/use-all-credit-card"
import { FeaturedPostList } from "@components/Post/CategoryList"
import { FeaturedPosts } from "@components/Post/FeaturedPosts"
import FixedBanner from "@components/Home/FixedBanner"
import SearchFilter from "@components/Search/SearchFilter"
import { AdSlot } from 'react-dfp'

const Section = ({
  isLast = false,
  mb = { xs: 0, sm: 8 },
  pb = { xs: 4, sm: 0 },
  children,
  ...rest
}) => (
  <Box mb={mb}>
    <Box pb={pb}>
      <Container {...rest}>{children}</Container>
    </Box>
  {/*
    {!isLast && (
      <Hidden smUp>
        <Divider />
      </Hidden>
    )}
  */}
  </Box>
)

const HomePage = ({ data, pageContext }) => {
  const offers = parseOffer(data.offers.nodes)
  const { totalCount } = data.offers

  const reset = useStoreActions(({ offerSearch }) => offerSearch.reset)
  reset()

  const homepageCarousel = data.siteConfig.nodes[0].data.homepage_carousel
  const homepageFixedBanner = data.siteConfig.nodes[0].data.homepage_fixed_banner
  const featuredOffers = parseSiteConfig(data.siteConfig.nodes[0].data.app_featured_offers).filter((ele, index) => index < 12)
  const sponsoredOffers = parseSiteConfig(data.siteConfig.nodes[0].data.homepage_sponsor_offers)

  const { featuredPostSubcategories } = pageContext
  const postsSubcategory1 = parsePost(data.postsSubcategoryFirst.nodes)
  const postsSubcategory2 = parsePost(data.postsSubcategorySecond.nodes)
  const postsSubcategory3 = parsePost(data.postsSubcategoryThird.nodes)
  const postsSubcategory4 = parsePost(data.postsSubcategoryFourth.nodes)
  const postsSubcategory5 = parsePost(data.postsSubcategoryFifth.nodes)
  const postsSubcategory6 = parsePost(data.postsSubcategorySixth.nodes)
  const postsSubcategory7 = parsePost(data.postsSubcategorySeventh.nodes)
  const postsSubcategory8 = parsePost(data.postsSubcategoryEighth.nodes)
  const postsSubcategory9 = parsePost(data.postsSubcategoryNineth.nodes)
  const postsSubcategory10 = parsePost(data.postsSubcategoryTenth.nodes)

  const offerIds = offers.map(({ prismicId }) => prismicId)
  const lastPublicationDate = offers && offers[0] && offers[0].last_publication_date
  const { siteMetadata } = data.site

  const [anchorRef, inView] = useEmailPopup()

  // Load Credit Cards at index page
  const updateCreditCards = useStoreActions(
    ({ allCreditCards }) => allCreditCards.update
  )
  const allCreditCardData = useAllCreditCard()

  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )

  const showSearchDrawer = useStoreState(
    ({ offerSearch }) => offerSearch.showSearchDrawer
  )

  useEffect(() => {
    toggleSearchDrawer(false)
    updateCreditCards(allCreditCardData)
  }, [])

  return (
    <Layout>
      <SEO />
      <JsonLd>
        {organizationSchema({
          name: siteMetadata.title,
          url: siteMetadata.siteUrl,
        })}
      </JsonLd>
      {/*
      <Top />
      <Container>
        <Box position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <Box position="absolute" bottom="100%" pb={{ xs: 1, sm: 2 }}>
            <Box mb={{ xs: 0.5, sm: 1 }}>
              <Box
                fontSize={{ xs: "1.125rem", sm: "1.5rem" }}
                fontWeight="500"
                component="h1"
                m={0}
              >
                個人化信用卡優惠平台
              </Box>
            </Box>
            <Box>
              <Typography variant="body1">立即搜尋屬於你的簽賬著數</Typography>
            </Box>
          </Box>
          <SearchInput height="3rem" />
        </Box>
      </Container>
      */}
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
        <SearchFilter height="3rem" />
      </Container>
      {/* for Phase0.5
      <Container px={{ xs: 0, sm: 3 }}>
        <Box mb={{ xs: 0, sm: 4 }}>
          <Announcement />
        </Box>
      </Container>
      */}
      <Container px={{ xs: 0, sm: 3 }} mt={{ xs: 0, sm: 1 }}>
        {/* Phase 2 margin */}
        {/* <Box mb={{ xs: 0, sm: 5.5 }}> */}
        {/* Phase 1 margin */}
        <Box mb={{ xs: 0, sm: 2.5 }} data-ele-section="home-offer-carousel">
          <HomepageCarousel items={homepageCarousel} />
          <Hidden smUp>
            <Divider />
          </Hidden>
        </Box>
      </Container>
      <FeaturedPosts
        Layout={Section}
        mb={{ xs: 1, sm: 2 }}
        data-ele-section="home-featured-post"
      />
      <AdSlot adUnit="Web_JetsoBee/jetsobee_home_special1_1x1" sizes={[1, 1]} />
      <Container px={{ xs: 0, sm: 3 }} mt={{ xs: 0, sm: 1 }}>
        <Box mb={{ xs: 0, sm: 2.5 }} data-ele-section="home-fixed-banner">
          <FixedBanner items={homepageFixedBanner} />
        </Box>
      </Container>
      <Section isLast mb={{ xs: 0, sm: 10 }} data-ele-section="home-offer-list">
        <Box pt={{ xs: 2, sm: 0 }} ref={anchorRef}>
          {Array.isArray(featuredOffers) && <OfferFeatured title="精選優惠" offers={featuredOffers} sponsor={sponsoredOffers} />}
        </Box>
        <Box maxWidth={360} m="auto" mt={{ xs: 2.5, sm: 4 }}>
          <Link href="/offers">
            <Button
              fullWidth
              minWidth={{ xs: 0, sm: 360 }}
              data-ele-name="btn-offer-show-more"
            >
              <Typography component="span">查看更多相似優惠</Typography>
            </Button>
          </Link>
        </Box>
        <EmailPopup inView={inView} />
        {/*
        <OfferShowMore exclude={offerIds} totalCount={totalCount} pageSize={12} startButton />
        {!showMore && (
          <Box maxWidth={360} m="auto" mt={{ xs: 2.5, sm: 4 }}>
            <Button
              fullWidth
              minWidth={{ xs: 0, sm: 360 }}
              data-ele-name="btn-offer-show-more"
              onClick={triggerShowMore}
            >
              <Typography component="span">查看更多相似優惠</Typography>
            </Button>
          </Box>
        )}
        {showMore && <OfferList offers={offers.slice(14, 26)} />}
        */}
      </Section>
      {/*
      <Section mb={{ xs: 2, sm: 10.5 }} p={0} pb={0}>
        <Subscription />
      </Section>
      */}
      {/* <Section mb={{ xs: 2, sm: 10 }} data-ele-section="home-post-list"> */}
      {/* <FeaturedPostList
        Layout={Section}
        mb={{ xs: 2, sm: 10 }}
        data-ele-section="home-post-list"
      /> */}
      {/* </Section> */}
      {postsSubcategory1.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory1}
          category={featuredPostSubcategories[0].post_subcategory}
          data-ele-section="home-post-list-1"
        />
      )}
      {postsSubcategory2.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory2}
          category={featuredPostSubcategories[1].post_subcategory}
          data-ele-section="home-post-list-2"
        />
      )}
      {postsSubcategory3.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory3}
          category={featuredPostSubcategories[2].post_subcategory}
          data-ele-section="home-post-list-3"
        />
      )}
      {postsSubcategory4.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory4}
          category={featuredPostSubcategories[3].post_subcategory}
          data-ele-section="home-post-list-4"
        />
      )}
      {postsSubcategory5.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory5}
          category={featuredPostSubcategories[4].post_subcategory}
          data-ele-section="home-post-list-5"
        />
      )}
      {postsSubcategory6.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory6}
          category={featuredPostSubcategories[5].post_subcategory}
          data-ele-section="home-post-list-6"
        />
      )}
      {postsSubcategory7.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory7}
          category={featuredPostSubcategories[6].post_subcategory}
          data-ele-section="home-post-list-7"
        />
      )}
      {postsSubcategory8.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory8}
          category={featuredPostSubcategories[7].post_subcategory}
          data-ele-section="home-post-list-8"
        />
      )}
      {postsSubcategory9.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory9}
          category={featuredPostSubcategories[8].post_subcategory}
          data-ele-section="home-post-list-9"
        />
      )}
      {postsSubcategory10.length > 0 &&(
        <FeaturedPostList
          posts={postsSubcategory10}
          category={featuredPostSubcategories[9].post_subcategory}
          data-ele-section="home-post-list-10"
        />
      )}
      {/* 
      <Section isLast>
        <OfferList title="為您推薦" offers={offers.slice(27, 33)} />
        <OfferShowMore
          exclude={offerIds}
          lastPublicationDate={lastPublicationDate}
          totalCount={totalCount}
          pageSize={12}
        />
      </Section>
      */}
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query HomepageQuery($today: Date, $featuredPostSubcategory1: ID, $featuredPostSubcategory2: ID, $featuredPostSubcategory3: ID, $featuredPostSubcategory4: ID,
   $featuredPostSubcategory5: ID, $featuredPostSubcategory6: ID, $featuredPostSubcategory7: ID, $featuredPostSubcategory8: ID, $featuredPostSubcategory9: ID, $featuredPostSubcategory10: ID)
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteConfig: allPrismicSiteConfig {
      nodes {
        data {
          homepage_carousel {
            image {
              fluid(maxWidth: 1300) {
                ...GatsbyPrismicImageFluid_noBase64
              }
              thumbnails {
                mobile {
                  fluid(maxWidth: 600) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
              }
            }
            desktop_url {
              __typename
              url
              id
              type
              document {
                ... on PrismicOffer {
                  id
                  uid
                  type
                  url
                  data {
                    offer_category {
                      uid
                    }
                    offer_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicPost {
                  id
                  uid
                  type
                  url
                  data {
                    post_category {
                      uid
                    }
                    post_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicCreditCard {
                  id
                  uid
                  type
                  url
                }
              }
              isBroken
              link_type
              target
            }
            mobile_url {
              __typename
              url
              id
              type
              document {
                ... on PrismicOffer {
                  id
                  uid
                  type
                  url
                  data {
                    offer_category {
                      uid
                    }
                    offer_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicPost {
                  id
                  uid
                  type
                  url
                  data {
                    post_category {
                      uid
                    }
                    post_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicCreditCard {
                  id
                  uid
                  type
                  url
                }
              }
              isBroken
              link_type
              target
            }
          }
          homepage_fixed_banner {
            image {
              fluid(maxWidth: 1300) {
                ...GatsbyPrismicImageFluid_noBase64
              }
              thumbnails {
                mobile {
                  fluid(maxWidth: 600) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
              }
            }
            desktop_url {
              __typename
              url
              id
              type
              document {
                ... on PrismicOffer {
                  id
                  uid
                  type
                  url
                  data {
                    date
                    offer_category {
                      uid
                    }
                    offer_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicPost {
                  id
                  uid
                  type
                  url
                  data {
                    date
                    post_category {
                      uid
                    }
                    post_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicCreditCard {
                  id
                  uid
                  type
                  url
                }
              }
              isBroken
              link_type
              target
            }
            mobile_url {
              __typename
              url
              id
              type
              document {
                ... on PrismicOffer {
                  id
                  uid
                  type
                  url
                  data {
                    date
                    offer_category {
                      uid
                    }
                    offer_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicPost {
                  id
                  uid
                  type
                  url
                  data {
                    date
                    post_category {
                      uid
                    }
                    post_subcategory {
                      uid
                    }
                  }
                }
                ... on PrismicCreditCard {
                  id
                  uid
                  type
                  url
                }
              }
              isBroken
              link_type
              target
            }
          }
          app_featured_offers {
            offer {
              url
              uid
              id
              isBroken
              document {
                ... on PrismicOffer {
                  prismicId
                  uid
                  type
                  data {
                    date
                    offer_category {
                      document {
                        ... on PrismicOfferCategory {
                          data {
                            name
                          }
                          uid
                          type
                        }
                      }
                    }
                    offer_subcategory {
                      document {
                        ... on PrismicOfferSubcategory {
                          uid
                          type
                          data {
                            name
                            offer_category {
                              uid
                              type
                            }
                            image {
                              url
                            }
                            image_color
                          }
                        }
                      }
                    }
                    featured_images {
                      image {
                        thumbnails {
                          thumbnail {
                            fluid(maxWidth: 240) {
                              ...GatsbyPrismicImageFluid_noBase64
                            }
                          }
                        }
                        url
                        alt
                      }
                    }
                    merchants {
                      merchant {
                        document {
                          ... on PrismicMerchant {
                            uid
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    banks {
                      bank {
                        document {
                          ... on PrismicBank {
                            uid
                            prismicId
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    credit_cards {
                      credit_card {
                        document {
                          ... on PrismicCreditCard {
                            uid
                            prismicId
                            data {
                              name
                              bank {
                                id
                              }
                              card_image {
                                alt
                                url
                                dimensions {
                                  width
                                  height
                                }
                                fixed(height: 42) {
                                  ...GatsbyPrismicImageFixed_noBase64
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    short_description {
                      text
                    }
                    tagged {
                      tag {
                        uid
                        type
                        document {
                          ... on PrismicTag {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    title
                    to(formatString: "LL", locale: "zh-tw")
                  }
                }
              }
            }
          }
          homepage_sponsor_offers {
            offer {
              url
              uid
              id
              isBroken
              document {
                ... on PrismicOffer {
                  prismicId
                  uid
                  type
                  data {
                    date
                    offer_category {
                      document {
                        ... on PrismicOfferCategory {
                          data {
                            name
                          }
                          uid
                          type
                        }
                      }
                    }
                    offer_subcategory {
                      document {
                        ... on PrismicOfferSubcategory {
                          uid
                          type
                          data {
                            name
                            offer_category {
                              uid
                              type
                            }
                            image {
                              url
                            }
                            image_color
                          }
                        }
                      }
                    }
                    featured_images {
                      image {
                        thumbnails {
                          thumbnail {
                            fluid(maxWidth: 240) {
                              ...GatsbyPrismicImageFluid_noBase64
                            }
                          }
                        }
                        url
                        alt
                      }
                    }
                    merchants {
                      merchant {
                        document {
                          ... on PrismicMerchant {
                            uid
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    banks {
                      bank {
                        document {
                          ... on PrismicBank {
                            uid
                            prismicId
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    credit_cards {
                      credit_card {
                        document {
                          ... on PrismicCreditCard {
                            uid
                            prismicId
                            data {
                              name
                              bank {
                                id
                              }
                              card_image {
                                alt
                                url
                                dimensions {
                                  width
                                  height
                                }
                                fixed(height: 42) {
                                  ...GatsbyPrismicImageFixed_noBase64
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    short_description {
                      text
                    }
                    tagged {
                      tag {
                        uid
                        type
                        document {
                          ... on PrismicTag {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    title
                    to(formatString: "LL", locale: "zh-tw")
                  }
                }
              }
            }
          }
        }
      }
    }
    featuredOffers: allPrismicOffer(
      limit: 1
      # ... filter: {data: {to: {gte: $today}}},
      filter: {
        data: {
          to: {
            gte: $today
          },
          date: {
            lte: $today
          }
        }
      }
      sort: {
        fields: [data___date, last_publication_date]
        order: [DESC, DESC]
      }
    ) {
      nodes {
        data {
          featured_images {
            image {
              fluid(maxWidth: 716) {
                ...GatsbyPrismicImageFluid_noBase64
              }
              alt
            }
          }
        }
        prismicId
        uid
        type
      }
    }
    offers: allPrismicOffer(
      limit: 33
      # ... filter: {data: {to: {gte: $today}}},
      filter: {
        data: {
          to: {
            gte: $today
          },
          date: {
            lte: $today
          }
        }
      }
      sort: {
        fields: [data___date, last_publication_date]
        order: [DESC, DESC]
      }
    ) {
      totalCount
      nodes {
        data {
          offer_category {
            document {
              ... on PrismicOfferCategory {
                data {
                  name
                }
                uid
                type
              }
            }
          }
          offer_subcategory {
            document {
              ... on PrismicOfferSubcategory {
                uid
                type
                data {
                  name
                  offer_category {
                    uid
                    type
                  }
                }
              }
            }
          }
          featured_images {
            image {
              thumbnails {
                thumbnail {
                  fluid(maxWidth: 240) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
              }
              url
              alt
            }
          }
          merchants {
            merchant {
              document {
                ... on PrismicMerchant {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
          banks {
            bank {
              document {
                ... on PrismicBank {
                  uid
                  prismicId
                  data {
                    name
                  }
                }
              }
            }
          }
          credit_cards {
            credit_card {
              document {
                ... on PrismicCreditCard {
                  uid
                  prismicId
                  data {
                    name
                    bank {
                      id
                    }
                    card_image {
                      alt
                      url
                      dimensions {
                        width
                        height
                      }
                      fixed(height: 42) {
                        ...GatsbyPrismicImageFixed_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          short_description {
            text
          }
          tagged {
            tag {
              uid
              type
              document {
                ... on PrismicTag {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
          title
          to(formatString: "LL", locale: "zh-tw")
        }
        prismicId
        last_publication_date
        uid
        type
      }
    }
    postsSubcategoryFirst: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory1
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategorySecond: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory2
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategoryThird: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory3
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategoryFourth: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory4
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            title
            date
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategoryFifth: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory5
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategorySixth: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory6
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategorySeventh: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory7
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategoryEighth: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory8
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategoryNineth: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory9
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    postsSubcategoryTenth: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          post_subcategory: {
            id: {
              eq: $featuredPostSubcategory10
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
  }
`
