import React, { useState, useEffect } from 'react'
import { map, path } from 'ramda'
import { linkResolver } from '@utils/link-resolver'
import HeroCarousel from '@components/Carousel/Hero'
import useDeviceDetect from "@hooks/use-device-detect"

const HomepageCarousel = ({ items }) => {

  const [isMobile, setIsMobile] = useState(true)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0

  useEffect(() => {
    setIsMobile(deviceWidth <= 640)
  }, [deviceWidth])

  const slideData = map((item) => ({
    ...item.desktop_url,
    image: path(['image'], item),
    url: item.desktop_url.link_type === 'Document' ? linkResolver(item.desktop_url.document) : path(['desktop_url', 'url'], item) ,
  }), items)

  const slideMobileData = map((item) => ({
    ...item.mobile_url,
    image: path(['image', 'thumbnails', 'mobile'], item),
    url: item.mobile_url.link_type === 'Document' ? linkResolver(item.mobile_url.document) : path(['mobile_url', 'url'], item) ,
  }), items)

  return isMobile ? <HeroCarousel items={slideMobileData} aspectRatio="1.5" /> : <HeroCarousel items={slideData} aspectRatio="2.5" />
}

export default HomepageCarousel
