import React, { useState, useEffect } from 'react'
import { map, path } from 'ramda'
import Grid from '@lib/ui/core/Grid'
import Box from '@lib/ui/core/Box'
import { linkResolver } from '@utils/link-resolver'
import HeroCarousel from '@components/Carousel/Hero'
import useDeviceDetect from "@hooks/use-device-detect"
import useImpression from '@hooks/use-impression'
import Img from 'gatsby-image'

const Banner = ({ url, type, target, image, keyindex }) => {
  const gaEvent = {
    eventCategory: "homepage_fixed_banner",
    eventLabel: url,
    ga4Event: "homepage_fixed_banner_impression",
    eventParams: {
      item_url: url,
      index: keyindex,
      item_type: type,
    },
    once: true,
  };
  const { observe } = useImpression(gaEvent);
  return (
    <a href={url} item_url={url} index={keyindex} target={target} data-ele-name="carousel-image" ref={observe}>
    {image.fluid && (
      <img src={image.fluid.src} alt={image.alt} style={{maxWidth: "100%"}} />
    )}
    </a>
  );
}

const FixedBanner = ({ items }) => {

  const [isMobile, setIsMobile] = useState(true)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0

  useEffect(() => {
    setIsMobile(deviceWidth <= 640)
  }, [deviceWidth])

  const slideData = map((item) => ({
    ...item.desktop_url,
    image: path(['image'], item),
    url: item.desktop_url.link_type === 'Document' ? linkResolver(item.desktop_url.document) : path(['desktop_url', 'url'], item) ,
  }), items)

  const slideMobileData = map((item) => ({
    ...item.mobile_url,
    image: path(['image', 'thumbnails', 'mobile'], item),
    url: item.mobile_url.link_type === 'Document' ? linkResolver(item.mobile_url.document) : path(['mobile_url', 'url'], item) ,
  }), items)

  const banners = isMobile ? slideMobileData : slideData

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      {(banners && banners[0] ? banners : []).map((item, index) => (
        <Grid item key={index} xs={12} sm={12} style={{textAlign: "center"}} >
         <Banner { ...item } keyindex={index} />
        </Grid>
      ))}
    </Grid>
  )

}

export default FixedBanner
