/* eslint-env browser */
import { useInView } from 'react-intersection-observer'

const useEmailPopup = () => {
  const [ref, inView] = useInView({
    rootMargin: '-100px 0px',
  })

  return [ref, inView]
}

export default useEmailPopup;
