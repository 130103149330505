/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import Image from "gatsby-image"
import Dotdotdot from "react-dotdotdot"
import { hasPath, path, join, useWith, or, identity } from "ramda"
import { linkResolver } from "@utils/link-resolver"
import Box from "@lib/ui/core/Box"
import Skeleton from "@lib/ui/core/Skeleton"
import Hidden from "@lib/ui/core/Hidden"
import Typography from "@lib/ui/core/Typography"
import Link from "@lib/ui/core/Link"
import TagList from "@components/Tag/List"
import Grid from "@lib/ui/core/Grid"
import useImpression from "@hooks/use-impression"
import useResponsive from "@hooks/use-responsive"
import { addGaEvent } from "@utils/ga-event"

const renderImage = (images, aspectRatio = 1.67777) => {
  const hasCoverImage = hasPath(["url"], images)
  const isGatsbyImage = hasPath(["thumbnails", "thumbnail", "fluid"], images)
    || hasPath([0, "image", "thumbnails", "thumbnail", "fluid"], images)
  const thumbnailPath = hasCoverImage
    ? path(["thumbnails", "thumbnail", "fluid"], images)
    : path([0, "image", "thumbnails", "thumbnail", "fluid"], images)
  const urlPath = hasCoverImage
    ? path(["url"], images)
    : path([0, "image", "url"], images)
  const altPath = hasCoverImage
    ? path(["alt"], images)
    : path([0, "image", "alt"], images)
  const width = [60, 120, 240, 360, 480]

  const parsedImage = isGatsbyImage
    ? {
      ...thumbnailPath,
      aspectRatio,
    }
    : {
      src: `${urlPath}&w=${width[2]}&h=${Math.floor(
        width[2] / aspectRatio
      )}&fit=crop&q=50`,
      srcSet: join(
        ", ",
        width.map(
          (w) => `${urlPath}&w=${w}&h=${Math.floor(
            w / aspectRatio
          )}&fit=crop&q=50 ${w}w`
        )
      ),
      sizes: "",
      aspectRatio,
    }

  return <Image fluid={parsedImage} alt={altPath} />
}

const PostCard = ({ post, key, fullWidth = false, loading = false }) => {
  const {
    featured_images,
    title,
    post_subcategory,
    lead,
    tagged,
    author,
    is_sponsored,
    date,
  } = post || {}
  const isLoadingOr = useWith(or, [identity, identity])(loading)
  const isSponsored = is_sponsored === true && key === 1
  const r = useResponsive()
  const { observe, unobserve } = useImpression({
    eventCategory: "homepage_featured_article",
    eventLabel: linkResolver(post),
    ga4Event: "post_impression",
    eventParams: {
      item_id: post.uid,
      item_name: post.title,
      item_type: "featured_post",
    },
    once: true,
  })

  const [isMobile, setIsMobile] = useState(true)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0

  useEffect(() => {
    setIsMobile(deviceWidth <= 640)
  }, [deviceWidth])

  const handleTagClick = (tag) => {
    addGaEvent({
      ga4Event: "tag_click",
      eventParams: {
        item_name: tag.name,
        content_type: "post",
        item_type: "post_tag",
      },
    })
  }

  useEffect(() => {
    return () => unobserve && unobserve()
  }, [])

  const ImageSection = ({ aspectRatio }) => (
    <Link href={linkResolver(post)} data-ele-name="post-image">
      <Box width="100%" display="inline" position="relative">
        {isSponsored && (
          <Typography
            variant="body2"
            component="span"
            style={{
              position: "absolute",
              background: "rgba(201,154,19,1)",
              color: "#fff",
              width: "50px",
              padding: "5px 10px",
              left: "20px",
              top: "0",
              zIndex: "9",
            }}
          >
            贊助
          </Typography>
        )}
        {renderImage(featured_images, aspectRatio)}
      </Box>
    </Link>
  )

  const DescriptionSection = () => (
    <Box px={1.75} pt={1.5} pb={2}>
      {isLoadingOr(hasPath(["name"], post_subcategory)) && (
        <Box mb={0.5} display={{ xs: "none", md: "block" }}>
          <Link
            href={linkResolver(post_subcategory)}
            data-ele-name="post-subcategory"
          >
            {!loading ? post_subcategory.name : <Skeleton width="20%" />}
          </Link>
        </Box>
      )}
      {isLoadingOr(title) && (
        <Box mb={1.25}>
          <Link href={linkResolver(post)} color="text.primary">
            <Typography
              variant={r({ xs: "h4", sm: "h3", md: "h3" })}
              component={Dotdotdot}
              clamp={isMobile ? 3 : 2}
              tagName="h3"
            >
              {!loading ? (
                title
              ) : (
                <>
                  <Skeleton width="100%" />
                  <Skeleton width="60%" />
                </>
              )}
            </Typography>
          </Link>
        </Box>
      )}
      {isLoadingOr(hasPath(["text"], lead)) && (
        <Link
          href={linkResolver(post)}
          data-ele-name="post-description"
          color="text.primary"
        >
          <Box mb={1.75} display={{ xs: "none", md: "block" }}>
            <Typography
              variant="body1"
              component={Dotdotdot}
              clamp={2}
              tagName="p"
            >
              {!loading ? (
                lead.text
              ) : (
                <>
                  <Skeleton />
                  <Skeleton />
                </>
              )}
            </Typography>
          </Box>
        </Link>
      )}

      {!loading ? (
        tagged && (
          <Box display={{ xs: "none", md: "block" }}>
            <TagList
              tags={tagged}
              type="post"
              handleTagClick={handleTagClick}
            />
          </Box>
        )
      ) : (
        <Skeleton width="40%" />
      )}
    </Box>
  )

  return (
    <Box boxShadow={9} mx={{ xs: 0.5, sm: 1 }} borderRadius="1rem" minHeight={{ xs: "185px", sm: "200px", md: "420px" }} height="100%" overflow="hidden" bgcolor="background.default" ref={observe}>
      {fullWidth ? (
        <>
          <Hidden xsDown>
            <Grid container alignItems="stretch" spacing={2}>
              {isLoadingOr(hasPath([0, "image", "url"], featured_images)) && (
                <Grid item sm={6} md={4} lg={5}>
                  {!loading ? (
                    <ImageSection aspectRatio={1} />
                  ) : (
                    <Box pb="100%" clone>
                      <Skeleton variant="rect" width="100%" />
                    </Box>
                  )}
                </Grid>
              )}
              <Grid item sm={5} md={7} lg={7}>
                <DescriptionSection />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <ImageSection />
            <DescriptionSection />
          </Hidden>
        </>
      ) : (
        <>
          <ImageSection />
          <DescriptionSection />
        </>
      )}
    </Box>
  )
}

export default PostCard
