/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'
import Dotdotdot from "react-dotdotdot"
import Img from "gatsby-image"
import { hasPath, path, useWith, or, identity, join } from "ramda"
import { linkResolver } from "@utils/link-resolver"
import flattenListItemPathIfExist from "@services/prismic/helpers/flatten-list-item-path"
import Box from "@lib/ui/core/Box"
import Grid from "@lib/ui/core/Grid"
import Typography from "@lib/ui/core/Typography"
import Skeleton from "@lib/ui/core/Skeleton"
import Link from "@lib/ui/core/Link"
import { TagLink } from "@components/Tag"
import CreditCardList from "@components/Offer/CreditCard/List"
import { OfferContextStore } from "@context"
import useImpression from '@hooks/use-impression'
import { addGaEvent } from '@utils/ga-event'

const renderImage = (images) => {
  const isCoverImage = hasPath(["url"], images)
  const isGatsbyImage = hasPath(["thumbnails", "thumbnail", "fluid"], images) || hasPath([0, "image", "thumbnails", "thumbnail", "fluid"], images)
  const thumbnailPath = isCoverImage ? path(["thumbnails", "thumbnail", "fluid"], images) : path([0, "image", "thumbnails", "thumbnail", "fluid"], images)
  const urlPath = isCoverImage ? path(["url"], images) : path([0, "image", "url"], images)
  const altPath = isCoverImage ? path(["alt"], images) : path([0, "image", "alt"], images)
  const aspectRatio = 1
  const width = [60, 120, 240, 360, 480]

  const parsedImage = isGatsbyImage
    ? {
      ...thumbnailPath,
      aspectRatio,
    }
    : {
      src: `${urlPath}&w=${
        width[2]
      }&h=${Math.floor(width[2] / aspectRatio)}&fit=max&q=50`,
      srcSet: join(
        ", ",
        width.map(
          (w) => `${urlPath}&w=${w}&h=${Math.floor(w / aspectRatio)}&fit=max&q=50 ${w}w`
        )
      ),
      sizes: "",
      aspectRatio,
    }
  // else {
  //  parsedImage = isGatsbyImage
  //   ? {
  //     ...path(
  //       ["thumbnails", "thumbnail", "fluid"],
  //       featured_images
  //     ),
  //     aspectRatio,
  //   }
  //   : {
  //     src: `${path(["url"], featured_images)}&w=${
  //       width[2]
  //     }&h=${Math.floor(width[2] / aspectRatio)}&fit=crop&q=50`,
  //     srcSet: join(
  //       ", ",
  //       width.map(
  //         (w) => `${path(
  //           ["url"],
  //           featured_images
  //         )}&w=${w}&h=${Math.floor(w / aspectRatio)}&fit=crop&q=50 ${w}w`
  //       )
  //     ),
  //     sizes: "",
  //     aspectRatio,
  //   }
  // }

  return (
    <Img fluid={parsedImage} alt={altPath} />
  )
}

const Card = ({ offer, loading = false, fullWidth = false }) => {
  const {
    title,
    to,
    credit_cards,
    banks,
    featured_images,
    offer_subcategory,
    short_description,
    tagged,
    is_sponsored,
  } = offer || {}

  const isSelectedCard = credit_cards && Array.isArray(credit_cards) && credit_cards.length > 0
  const isSelectedBank = banks && Array.isArray(banks) && banks.length > 0
  const isLoadingOr = useWith(or, [identity, identity])(loading)
  const isSponsored = is_sponsored === true
  const nullRef = useRef(null);

  const { observe, unobserve } = useImpression({
    eventCategory: isSponsored ? "home_offer_list_sponsor_offer" : undefined,
    eventLabel: linkResolver(offer),
    ga4Event: "offer_impression",
    eventParams: {
      item_id: offer.uid,
      item_name: offer.title,
      item_type: "featured_offer",
    },
    once: true,
  })

  const handleTagClick = tag => {
    addGaEvent({
      ga4Event: "tag_click",
      eventParams: {
        item_name: tag.name,
        content_type: "offer",
        item_type: "offer_listing_tag",
      },
    })
  }

  useEffect(() => {
    return () => unobserve && unobserve()
  }, [])

  return (
    <Box boxShadow={9} borderRadius="1rem" height="100%" p={2} bgcolor={isSponsored ? "rgba(251, 231, 77, 0.15)" : "background.default"} ref={observe}>
      {/* <Box mr={fullWidth && 2} clone> */}
      <Grid container alignItems="stretch" spacing={fullWidth ? 0 : 2}>
        {/* Left Column */}
        {/* <Box mr={fullWidth && 2} clone> */}
        {/* style={{ "margin-right" : "16px"}} */}
        <Grid mr={fullWidth ? 2 : 0} item xs={4} sm={fullWidth && 2}>
          {isLoadingOr(hasPath([0, "image"], featured_images) || hasPath(["url"])) && (
            <Box mb={1.25} position="relative">
              <Link href={linkResolver(offer)} data-ele-name="offer-image" >
                <Box borderRadius="1rem" bgcolor="grey.100" clone>
                  {!loading ? (
                    renderImage(featured_images)
                  ) : (
                    <Box pb="100%" clone>
                      <Skeleton variant="rect" width="100%" />
                    </Box>
                  )}
                </Box>
              </Link>
              {offer_subcategory && offer_subcategory.image && offer_subcategory.image.url &&
              (
              <Box pt={1} position="absolute" bgcolor={offer_subcategory.image_color} borderRadius="0 1rem" width="32px" minHeight="32px" textAlign="center" bottom={0} left={0}>
                {!loading ? ( <img width="16px" height="16px" src={offer_subcategory.image.url} alt={offer_subcategory.name} /> ) : (
                    <Box pb="100%" clone>
                      <Skeleton variant="rect" width="100%" />
                    </Box>
                  )}
              </Box>)}
            </Box>
          )}
          {!loading ? (
            <>
              {Array.isArray(tagged)
                && tagged.map(({ tag }) => (
                  <Box
                    key={tag.uid}
                    color="actionText.active"
                    mb={{ xs: 0.25, sm: 0.5 }}
                  >
                    <TagLink to={linkResolver(tag)} key={tag.uid} onClick={() => handleTagClick(tag)}>
                      {tag.name}
                    </TagLink>
                  </Box>
                ))}
            </>
          ) : (
            <>
              <Skeleton width="60%" />
              <Skeleton width="60%" />
            </>
          )}
        </Grid>
        {/* </Box> */}
        {/* Right Column */}
        <Grid item xs={fullWidth ? 7 : 8} sm={fullWidth && 9}>
          <Link
            href={linkResolver(offer)}
            color="text.primary"
            data-ele-name="offer-description"
          >
            {isLoadingOr(title) && (
              <Box mb={1.25} overflow="hidden" display="flex">
                <Typography
                  variant="h3"
                  component={Dotdotdot}
                  clamp={2}
                  tagName="h3"
                >
                  {!loading ? (
                    title
                  ) : (
                    <>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </>
                  )}
                </Typography>
                {isSponsored &&(<Typography variant="body2" component="span" style={{marginLeft: 'auto', minWidth: '3rem', textAlign: 'right', fontWeight: 'bold', color: '#CEA542'}}>贊助</Typography>)}
              </Box>
            )}
            {!loading ? (
              <>
                {hasPath(["name"], offer_subcategory) && (
                  <Box
                    color={offer_subcategory.image_color}
                    my={2}
                  >
                    <Link
                      href={linkResolver(offer_subcategory)}
                      data-ele-name="offer-subcategory"
                      color={offer_subcategory.image_color}
                      fontWeight={800}
                    >
                      <Box component="span" fontSize="0.9rem" border={1} borderColor={offer_subcategory.image_color} borderRadius="3px" px={1} py={0.25}>
                      {offer_subcategory.name}
                      </Box>
                    </Link>
                  </Box>
                )}
              </>
            ) : (
              <Skeleton width="2rem" />
            )}
            {isLoadingOr(to) && (
              <Box mb={2} overflow="hidden">
                <Typography variant="body1">
                  {!loading ? <>優惠期至: {to}</> : <Skeleton width="60%" />}
                </Typography>
              </Box>
            )}
          </Link>
          {!loading ? (
            <>
              <OfferContextStore.Provider value={title}>
                {isSelectedCard 
                  && !isSelectedBank && (
                  <Box display="flex" flexWrap="wrap" mb={0}>
                    <CreditCardList
                      cards={flattenListItemPathIfExist(
                        ["credit_card"],
                        credit_cards
                      )}
                      max={4}
                      onlyXsHeight
                    />
                  </Box>
                )}
                {isSelectedCard 
                  && isSelectedBank && (
                  <Box display="flex" flexWrap="wrap" mb={0}>
                    <CreditCardList
                      cards={flattenListItemPathIfExist(
                        ["credit_card"],
                        credit_cards
                      )}
                      banks={banks}
                      max={4}
                      onlyXsHeight
                    />
                  </Box>
                )}
                {!isSelectedCard
                  && isSelectedBank && (
                    <Box display="flex" flexWrap="wrap" mb={0}>
                      <CreditCardList
                        banks={banks}
                        max={4}
                        onlyXsHeight
                      />
                    </Box>
                )}
                {!isSelectedCard
                  && !isSelectedBank && (
                    <Box display="flex" flexWrap="wrap" mb={0}>
                      <CreditCardList
                        max={4}
                        onlyXsHeight
                      />
                    </Box>
                )}
              </OfferContextStore.Provider>
            </>
          ) : (
            <Box display="flex">
              <Skeleton variant="rect" width={40} height={25} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Card
