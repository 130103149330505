/* eslint-disable camelcase */
import React from 'react'
import Img from 'gatsby-image'
import { hasPath, path, curry, or, join } from 'ramda'
import Dotdotdot from 'react-dotdotdot'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import Skeleton from '@lib/ui/core/Skeleton'


const renderImage = (images) => {
  const hasCoverImage = hasPath(["url"], images)
  const urlPath = hasCoverImage ? path(["url"], images) : path([0, "image", "url"], images)
  const altPath = hasCoverImage ? path(["alt"], images) : path([0, "image", "alt"], images)

  const width = 120
  const height = 90

  const parsedImage = {
    src: `${urlPath}&w=${width}&h=${height}&fit=max&q=50`,
    srcSet: join(', ', ['3', '3.5', '4'].map((dpr) => `${urlPath}&w=${width}&h=${height}&fit=max&q=50&dpr=${dpr} ${dpr}x`)),
    width,
    height,
  }
  return (
    <Img fixed={parsedImage} alt={altPath} />
  )
}

const Card = ({ offer, loading = false }) => {
  const { title, to, featured_images } = offer || {}

  const isLoadingOr = curry(or)(loading)

  return (
    <Box display="flex" alignItems="center" boxShadow={6} overflow="hidden" borderRadius="1rem">
      { isLoadingOr(hasPath([0, 'image'], featured_images)) && (
        <Box flexShrink={0} bgcolor="grey.100" clone>
          { !loading ? renderImage(featured_images) : (
            <Skeleton variant="rect" width={120} height={90} />
          )}
        </Box>
      )}
      <Box px={{ xs: 1.25, sm: 2 }} flexShrink={1} flexGrow={1}>
        { isLoadingOr(title) && (
          <Box mb={1}>
            <Typography variant="h4" component={Dotdotdot} clamp={2} tagName="h3">
              { !loading ? title : (
                <Skeleton />
              )}
            </Typography>
          </Box>
        )}
        { isLoadingOr(to) && (
          <Typography variant="body2">
            { !loading ? `優惠期至：${to}` : (
              <Skeleton width="10rem" />
            )}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default Card
