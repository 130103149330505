import React, { useState, useEffect } from 'react'
import { Box, Typography, SvgIcon, Grid } from '@lib/ui/core'
// import useEmailPopup from '@hooks/use-view-scroll'
// import EmailPopup from '@components/EmailPopup'
import Card from './Card'
import { path } from 'ramda'
import Link from '@components/Link'
import { linkResolver } from '@utils/link-resolver'
import { ReactComponent as Logo } from '@assets/icons/logo-icon.svg'
import DfpAdSlot from '@components/Ads/DfpAdSlot'

const Featured = ({ title, offers, sponsor, loading = false, fullWidth = false }) => {
  if (!(Array.isArray(offers) && offers.length > 0)) {
    return null
  }

  const tzoffset = (new Date()).getTimezoneOffset() * 60000
  const today = (new Date(Date.now() - tzoffset)).toISOString().substring(0, 10)

  const offersFilter = offers.filter((o) => o.offer.isBroken !== true && o.offer.date <= today)

  const sponsorFilter = sponsor.filter((o) => o.offer.isBroken !== true && o.offer.date <= today)
  sponsorFilter.forEach(function(sponsorItem){
      sponsorItem.offer.is_sponsored = true
  });

  const sponsorFilterLength = sponsorFilter.length > 3 ? 4 : sponsorFilter.length

  const [featuredList, setFeaturedList] = useState([])
  
  const addsponsoredList = async () => {
    const featured = offersFilter.sort(()=>Math.random() - 0.5).slice(1, (19 - sponsorFilterLength))
    if(sponsorFilter.length > 3) {
      featured.splice(0, 0, sponsorFilter[0])
      featured.splice(5, 0, sponsorFilter[1])
      featured.splice(10, 0, sponsorFilter[2])
      featured.splice(15, 0, sponsorFilter[3])
    }
    else if(sponsorFilter.length === 3) {
      featured.splice(0, 0, sponsorFilter[0])
      featured.splice(5, 0, sponsorFilter[1])
      featured.splice(10, 0, sponsorFilter[2])
    }
    else if(sponsorFilter.length === 2) {
      featured.splice(0, 0, sponsorFilter[0])
      featured.splice(5, 0, sponsorFilter[1])
    }
    else if(sponsorFilter.length === 1) {
      featured.splice(0, 0, sponsorFilter[0])
    }
    setFeaturedList(featured)
  }

  useEffect(() => {
    addsponsoredList()
  }, [])




  // const [anchorRef, inView, listInView] = useEmailPopup({ title })
  return (
    <Box mb={2.5}>
      {title && (
        <Box display="flex" justifyContent="space-between" py={{xs: 2, md:3}} alignItems="center">
        <SvgIcon component={Logo} fontSize="2rem" mr={1} />
        <Typography variant="h2" component="h2">
          {title}
        </Typography>

        <Box ml="auto">
        <Link to="/offers">
          <Box display="flex" color="#CEA542" alignItems="center">
            <Typography variant="h4" component="span">查看所有</Typography>
          </Box>
        </Link>
        </Box>
      </Box>
      )}
      <Grid container spacing={2}>
        {featuredList.map((item, index) => {
          if (index === 8 || index === 14) {
            return (
            <>
              <Grid item md={!fullWidth && 4} sm={!fullWidth && 6} xs={12} key={item.offer.uid}>
                <Card offer={item.offer} loading={item.offer ? false : loading} fullWidth={fullWidth} />
              </Grid>
              <Grid item md={12} sm={12} xs={12} key={index}>
                <Box mx="auto" width="300px">
                  <DfpAdSlot adUnit="Web_JetsoBee/Jetsobee_Home_LREC_300x250" sizes={[300, 250]} />
                </Box>
              </Grid>
            </>
            ) 
          }
          else {
            return (
            <>
              <Grid item md={!fullWidth && 4} sm={!fullWidth && 6} xs={12} key={item.offer.uid}>
                <Card offer={item.offer} loading={item.offer ? false : loading} fullWidth={fullWidth} />
              </Grid>
            </>
            )
          }
        })}
      </Grid>
      {/* <EmailPopup inView={inView} listInView={listInView} title={title} /> */}
    </Box>
  )
}


export default Featured
