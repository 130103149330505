import React from 'react'
import { Box, Typography, SvgIcon, Grid } from '@lib/ui/core'
// import useEmailPopup from '@hooks/use-view-scroll'
// import EmailPopup from '@components/EmailPopup'
import Card from './Card'
import { ReactComponent as Logo } from '@assets/icons/logo-icon.svg'
import DfpAdSlot from '@components/Ads/DfpAdSlot'

const MobileList = ({ title, offers, loading = false, fullWidth = false, eventCategory }) => {
  if (!(Array.isArray(offers) && offers.length > 0)) {
    return null
  }

  // const [anchorRef, inView, listInView] = useEmailPopup({ title })

  return (
    <Box mb={2.5}>
      {title && (
        <Box display="flex" justifyContent="space-between" m={2} alignItems="center">
          <SvgIcon component={Logo} fontSize="1.5rem" mr={1} />
          <Typography variant="subtitle1" component="h2">{title}</Typography>
          <Box ml="auto">
          </Box>
        </Box>
      )}
      <Grid container spacing={2}>
        {offers.map((offer, index) => {
          if(index === 4 || index === 9 || index === 14)
            return (
            <>
              <Grid item md={!fullWidth && 4} sm={!fullWidth && 6} xs={12} key={index}>
                <Card offer={offer} loading={offer ? false : loading} fullWidth={fullWidth} eventCategory={eventCategory} />
              </Grid>
              <Box mx="auto" width="300px" display={{ xs: 'block', md: 'none' }}>
                <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec2_300x250" sizes={[300, 250]} />
              </Box>
            </>
            )
          else if(index === 19)
            return (
            <>
              <Grid item md={!fullWidth && 4} sm={!fullWidth && 6} xs={12} key={index}>
                <Card offer={offer} loading={offer ? false : loading} fullWidth={fullWidth} eventCategory={eventCategory} />
              </Grid>
              <Box mx="auto" width="300px" display={{ xs: 'block', md: 'none' }}>
                <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec3_300x250" sizes={[300, 250]} />
              </Box>
            </>
            )
          else
            return(
            <>
              <Grid item md={!fullWidth && 4} sm={!fullWidth && 6} xs={12} key={index}>
                <Card offer={offer} loading={offer ? false : loading} fullWidth={fullWidth} eventCategory={eventCategory} />
              </Grid>
            </>
            )
        })}
      </Grid>
      {/* <EmailPopup inView={inView} listInView={listInView} title={title} /> */}
    </Box>
  )
}


export default MobileList
