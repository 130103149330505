import React from 'react'
import Box from '@lib/ui/core/Box'
import Link from '@components/Link'
import { linkResolver } from '@utils/link-resolver'
import Card from './Card'


const List = ({ offers, loading = false }) => {
  if (!(Array.isArray(offers) && offers.length > 0)) {
    return null
  }

  return (
    <Box>
      {offers.map((offer, index) => (
        // <Box key={index} mb={1.25} ref={anchorRef}>
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} mb={1.25}>
          <Link to={linkResolver(offer)} color="text.primary" data-ele-name="offer-item">
            <Card offer={offer} loading={offer ? false : loading} />
          </Link>
        </Box>
      ))}
    </Box>
  )
}


export default List
