/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FormInput from '@components/SurveyForm/FormInput'
import ErrorMsg from '@components/SurveyForm/ErrorMsg'
import Message from '@components/Message'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import Button from '@lib/ui/core/Button'
import Modal from '@lib/ui/core/Modal'
import SvgIcon from '@lib/ui/core/SvgIcon'
import { postForm } from '@services/api/subscription'
import { ReactComponent as SubscribeIcon } from '@assets/icons/symbol_email.svg'

const EmailPopup = ({ inView, dataEleSection }) => {
  const sessionShow = useStoreState((state) => state.emailPopup.showPopup)
  const [open, setOpen] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const hidePopup = useStoreActions((actions) => actions.emailPopup.hidePopup)
  const updateShowPopup = useStoreActions((actions) => actions.emailPopup.updateShowPopup)
  const windowGlobal = typeof window !== 'undefined' && window
  const lastPopup = windowGlobal ? windowGlobal.localStorage.getItem('last_popup') : null

  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000
  const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs
  useEffect(() => {
    if (inView) {
      setIsShow(true)
      if (lastPopup) {
        if (timestampThirtyDaysAgo < lastPopup) {
          hidePopup()
        } else {
          updateShowPopup()
          if(windowGlobal)
            windowGlobal.localStorage.removeItem('last_popup')
        }
      } else {
        updateShowPopup()
        if(windowGlobal)
          windowGlobal.localStorage.setItem('last_popup', new Date().getTime())
      }
    }
  }, [inView])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('電郵地址格式不正確').required('此欄必須填寫'),
    }),
    onSubmit: (values) => {
      const res = postForm(values)
      res.then((response) => {
        if (response.status === 200 && windowGlobal) {
          windowGlobal.localStorage.setItem('subscribe_email', true)
          hidePopup()
        }
      }).catch(() => {
        setOpen(true)
      })
    },
  })
  
  return (
    <Modal open={isShow && sessionShow} onClose={hidePopup} data-ele-section={dataEleSection}>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Box mb={1} clone>
            <SvgIcon component={SubscribeIcon} fontSize="4.6rem" />
          </Box>
          <Typography variant="h3">追蹤您的最新信用卡優惠</Typography>
          <Box mt=".6rem" mb="1rem">
            <Typography>立刻留下您的電郵地址，當有適合您的優惠，我們會立刻通知您！</Typography>
          </Box>
          <FormInput
            name="email"
            placeholder="您的電郵地址"
            border={formik.touched.email && formik.errors.email}
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (<ErrorMsg mb=".5rem" textAlign="left">{formik.errors.email}</ErrorMsg>) : null}
          <Button data-ele-name="subscribe" width="100%" type="submit" mb={2}>
            <Typography>追蹤精選信用卡優惠</Typography>
          </Button>
        </Box>
        {open && <Message open={open} message="出現錯誤，請刷新再試！" severity="error" onClose={() => setOpen(false)} />}
      </form>
    </Modal>
  )
}

export default React.memo(EmailPopup)
