import React from 'react'
import styled from 'styled-components'

import MuiModal from '@material-ui/core/Modal'
import Box from '@lib/ui/core/Box'
import ButtonBase from '@lib/ui/core/ButtonBase'
import SvgIcon from '@lib/ui/core/SvgIcon'
import { ReactComponent as Close } from '@assets/icons/close.svg'

const Modal = styled(({ open, onClose = () => {}, hasIcon = true, dataEleSection, children, ...props }) => (
  <MuiModal
    {...props}
    open={open}
    onClose={onClose}
  >
    <Box
      data-ele-section={dataEleSection}
      position="relative"
      width="100%"
      maxWidth="420px"
      bgcolor="background.default"
      pt="1.8rem"
      px={{ xs: '1rem', md: '1.8rem' }}
      pb={{ xs: '1rem', md: '1.8rem' }}
      borderRadius=".9rem"
      textAlign="center"
    >
      {hasIcon ? (
        <Box position="absolute" right=".9rem" top=".9rem">
          <ButtonBase data-ele-name="close-button" onClick={onClose}>
            <SvgIcon component={Close} fontSize="1rem" />
          </ButtonBase>
        </Box>
      ) : null}
      {children}
    </Box>
  </MuiModal>
))`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 .9rem;
  outline: 0;
`

export default Modal
