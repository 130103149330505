import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Box from '@lib/ui/core/Box'

const TopRoot = styled(React.forwardRef(({ imageurl, ...props }, ref) => (
  <Box ref={ref} {...props} />
)))`
  background-repeat: no-repeat;
  ${({ theme, imageurl }) => `
    background-image: url(${imageurl.xs.src});
    background-position: calc(100% + 128px) calc(50% - 8px);
    ${theme.breakpoints.up('sm')} {
      background-image: url(${imageurl.sm.src});
      background-position: calc(100% + 88px) calc(50% - 8px);
    }
    ${theme.breakpoints.up('md')} {
      background-image: url(${imageurl.sm.src});
      background-position: calc(100% + 16px) calc(50% - 8px);
    }
  `}
`

const Top = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "card-background.png" }) {
        childImageSharp {
          xs: fixed(height: 232) {
            ...GatsbyImageSharpFixed
          }
          sm: fixed(height: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <TopRoot bgcolor="primary.main" height={{ xs: "8rem", sm: "12.5rem" }} imageurl={data.placeholderImage.childImageSharp}>
      { children }
    </TopRoot>
  )
}

export default Top
