import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const useInfiniteScroll = ({ next, inViewOptions, hasNextPage }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [ref, inView, entry] = useInView(inViewOptions)

  useEffect(() => {
    if (hasNextPage()) {
      if ((inView && !loading) || (trigger && !loading)) {
        setLoading(true);
        (async function loadNext() {
          const result = await next()
          if (result) {
            setLoading(false)
          }
        }())
        setTrigger(false)
        setPage((prev) => prev + 1)
      }
    }
  }, [inView, loading, trigger])

  return [
    ref,
    {
      loading,
      page,
      trigger,
      setTrigger,
      inView,
      entry,
    },
  ]
}

export default useInfiniteScroll;
