import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { parseSiteConfig } from '@services/prismic/helpers'
import List from './List'

const Data = ({ Layout, ...props }) => {
  const query = useStaticQuery(graphql`
    query {
      allPrismicSiteConfig {
        nodes {
          data {
            app_featured_posts {
              post {
                url
                uid
                id
                isBroken
                document {
                  ... on PrismicPost {
                    prismicId
                    uid
                    type
                    data {
                      is_sponsored
                      date
                      featured_images {
                        image {
                          fluid(maxWidth: 600, maxHeight: 320) {
                            ...GatsbyPrismicImageFluid_noBase64
                          }
                          url
                          alt
                        }
                      }
                      title
                      post_category {
                        uid
                        type     
                        document {
                          ... on PrismicPostCategory {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                      post_subcategory {
                        uid
                        type
                        document {
                          ... on PrismicPostSubcategory {
                            id
                            data {
                              name
                              post_category {
                                uid
                              }
                            }
                          }
                        }
                      }
                      lead {
                        text
                      }
                      tagged {
                        tag {
                          uid
                          type
                          document {
                            ... on PrismicTag {
                              id
                              data {
                                name
                              }
                            }
                          }
                        }
                      }
                      author {
                        uid
                        document {
                          ... on PrismicAuthor {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const articleArray = parseSiteConfig(query.allPrismicSiteConfig.nodes[0]).app_featured_posts

  const [sponsoredList, setSponsoredList] = useState([])
  const [nonSponsoredArticle, setNonSponsoredArticle] = useState([])
  const [sponsoredArticle, setSponsoredArticle] = useState([])

  const tzoffset = (new Date()).getTimezoneOffset() * 60000
  const today = (new Date(Date.now() - tzoffset)).toISOString().substring(0, 10)

  const fetchsponsoredList = async () => {
    let sponsoredData = []
    let nonData = []
    let featuredArticles = []
    articleArray.forEach(function(article){
      if(article.post.isBroken !== true && article.post.date <= today) {
        if(article.post.is_sponsored) {
          sponsoredData.push(article)
        }
        else {
          nonData.push(article)
        }
        featuredArticles.push(article)
      }
    });
    setNonSponsoredArticle([...nonData]);
    setSponsoredArticle([...sponsoredData]);
        
    const randomId = Math.floor(Math.random() * sponsoredArticle.length)
    if(sponsoredArticle.length > 2) {
      setSponsoredList(sponsoredArticle.sort(()=>Math.random() - 0.5))
    }
    else if(sponsoredArticle.length === 2) {
      setSponsoredList(sponsoredArticle.sort(()=>Math.random() - 0.5).concat(nonSponsoredArticle[randomId]))
    }
    else if(sponsoredArticle.length === 1) {
      setSponsoredList(nonSponsoredArticle.splice(1, 0, sponsoredArticle[0]))
    }else {
      setSponsoredList(featuredArticles)
    }
  }

  useEffect(() => {
    fetchsponsoredList()
  }, [])

  return (
    <>
      <Layout {...props}>
        <List posts={sponsoredList} title="精選文章" totalCount={sponsoredList.length} />
      </Layout>
    </>
  )
}

export default React.memo(Data)
