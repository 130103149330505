import React from 'react'
import { Box, Typography, SvgIcon, Grid } from '@lib/ui/core'
import useResponsive from '@hooks/use-responsive'
import { ReactComponent as Arrow } from '@assets/icons/arrow.svg'
import Link from '@components/Link'
import { linkResolver } from '@utils/link-resolver'
import { path } from 'ramda'
import Card from './Card'
import Slider from 'react-slick';
import styled from 'styled-components';

const ArticleSlider = styled(Slider)`
   .slick-prev {
    left: -35px;
    z-index: 999;
    width: 40px;
    height: 40px;
  }
  .slick-next {
    right: -35px;
    z-index: 999;
    width: 40px;
    height: 40px;
  }
  .slick-prev:before, .slick-next:before {
    color: gray;
    font-size: 40px;
  }
  .slick-slide {
    margin-bottom: 10px;
  }
  @media (max-width: 600px) {
    .MuiTypography-h4 {
      font-weight: normal
    }
  }
`

const CardList = ({ posts, category, title, totalCount = 0, fullWidth = false, loading }) => {
  const r = useResponsive()
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 2.2,
          slidesToScroll: 2,
          arrows: false
        }
      }
    ]
  };
  return (
    <Box>
      {!fullWidth
      && (
      <Box display="flex" justifyContent="space-between" py={{xs: 2, md:3}} alignItems="center">
        <Typography variant="h2" component="h2">
          {category.document.data.name}
        </Typography>
        <Box display="flex" color="#CEA542" alignItems="center" width={{xs: "56px", sm: "70px"}} textAlign="right">
          <Link to={linkResolver(path([0, "post_subcategory"], posts))}>
            <Typography variant="h4" component="span">查看所有</Typography>
          </Link>  
        </Box>
      </Box>
      )}
      <Grid>
        <ArticleSlider {...settings}>
        {posts.map((post, i) => (
          <Card post={post} key={i} loading={post ? false : loading} />
        ))}
        </ArticleSlider>
      </Grid>
    </Box>
  )
}

export default CardList
